import request from '@/utils/request'


// 查询反馈类型列表
export function listType(query) {
  return request({
    url: '/dynamic/report-type/list',
    method: 'get',
    params: query
  })
}

// 查询反馈类型分页
export function pageType(query) {
  return request({
    url: '/dynamic/report-type/page',
    method: 'get',
    params: query
  })
}

// 查询反馈类型详细
export function getType(data) {
  return request({
    url: '/dynamic/report-type/detail',
    method: 'get',
    params: data
  })
}

export function changeIsOpen(data) {
  return request({
    url: '/dynamic/report-type/changeIsOpen',
    method: 'post',
    data: data
  })
}

// 新增反馈类型
export function addType(data) {
  return request({
    url: '/dynamic/report-type/add',
    method: 'post',
    data: data
  })
}

// 修改反馈类型
export function updateType(data) {
  return request({
    url: '/dynamic/report-type/edit',
    method: 'post',
    data: data
  })
}

// 删除反馈类型
export function delType(data) {
  return request({
    url: '/dynamic/report-type/delete',
    method: 'post',
    data: data
  })
}
